import React from 'react';

import GlobalStyles from '@styles/GlobalStyles';
import Seo from '@core/Seo';

const Layout = ({ children }) => (
  <>
    <GlobalStyles />
    <Seo />
    {children}
  </>
);

export default Layout;
