import { createGlobalStyle, css } from 'styled-components';
import mq from '@mq';

const Base = createGlobalStyle`
  html,
  body {
    font-size: 18px;
    line-height: 1.8;
    font-family: var(--font-family);
    background: var(--color-background);
    color: var(--color-text);
    -webkit-font-smoothing: antialiased;
    letter-spacing: 0.1em;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    width:  100%;
    height: 100%;
    bottom: 0;
    right:  0;
  }


`;

export default Base;
