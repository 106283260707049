import { createGlobalStyle } from 'styled-components';
import mq from '@mq';

const Vars = createGlobalStyle`
  :root {
    --color-black: #000;
    --color-white: #fff;
    --color-background: var(--color-black);
    --color-text: var(--color-white);
    --font-family: 'Normal-Grotesk', sans-serif;
    --motion: 200ms;
    --motion-double: 400ms;
    --motion-triple: 600ms;
  }
`;

export default Vars;
